<template>
    <div>
        <vue-title title="Контакты | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="text-center mb-3">
                <h1 class="mb-4 page-title">Наши контакты</h1>
            </div>
            <div class="row mb-5">
                <div class="col-12 col-lg-4 pr-3 pr-lg-0">
                    <div class="bg-white h-100">
                        <div class="media pt-3 pt-lg-5 mb-4 ml-4">
                            <img src="@/assets/images/contacts/map-marker.png" class="align-self-center mr-3" alt="РК, г. Алматы, ул. Майлина 85/2" style="width: 55px">
                            <div class="media-body ">
                                <h3 class="mt-1">РК, г. Алматы,<br>ул. Майлина 85/2</h3>
                            </div>
                        </div>
                        <div class="media pt-3 mb-4 ml-4">
                            <img src="@/assets/images/contacts/phone.png" class="align-self-center mr-3" alt="+7 (727) 312-21-10" style="width: 55px">
                            <div class="media-body">
                                <h3 class="mt-2"><a href="tel:+77273122110" style="color: #212529;">+7 (727) 312-21-10</a></h3>
                            </div>
                        </div>
                        <div class="media pt-3 mb-4 ml-4">
                            <img src="@/assets/images/contacts/phone.png" class="align-self-center mr-3" alt="+7 (771) 705-55-41" style="width: 55px">
                            <div class="media-body">
                                <h3 class="mt-2"><a href="tel:+77717055541" style="color: #212529;">+7 (771) 705-55-41</a></h3>
                            </div>
                        </div>
                        <div class="media pt-3 mb-4 ml-4">
                            <img src="@/assets/images/contacts/email.png" class="align-self-center mr-3" alt="info@kel.kz" style="width: 55px">
                            <div class="media-body">
                                <h3 class="mt-2"><a href="mailto:info@kel.kz" style="color: #212529;">info@kel.kz</a></h3>
                            </div>
                        </div>
                        <div class="media pt-3 mb-4 ml-4">
                            <img src="@/assets/images/contacts/www.png" class="align-self-center mr-3" alt="www.kel.kz" style="width: 55px">
                            <div class="media-body">
                                <h3 class="mt-2">www.kel.kz</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-8 pl-3 pl-lg-0">
                    <div class="bg-white h-100 d-flex align-items-center">
                        <img src="@/assets/images/contacts/1.png" class="w-100">
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import {Header, Footer} from '../../shared/components';
    import headerRoutes from './header-routes';

    export default {
        name: "Contacts",
        components: {
            Header,
            Footer
        },
        data() {
            return {
                headerRoutes
            }
        }
    }
</script>

<style scoped>
    .w-40 {
        width: 40% !important;
    }
    .w-45 {
        width: 45% !important;
    }
</style>
